export const Checked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.916 4.626C20.0815 4.73634 20.1963 4.90789 20.2353 5.10293C20.2743 5.29796 20.2343 5.5005 20.124 5.666L11.124 19.166C11.0624 19.2582 10.9811 19.3357 10.886 19.3927C10.7909 19.4498 10.6843 19.485 10.5739 19.4959C10.4636 19.5068 10.3521 19.4931 10.2477 19.4558C10.1433 19.4185 10.0484 19.3584 9.96997 19.28L3.96997 13.28C3.83749 13.1378 3.76537 12.9498 3.76879 12.7555C3.77222 12.5612 3.85093 12.3758 3.98835 12.2384C4.12576 12.101 4.31114 12.0223 4.50545 12.0188C4.69975 12.0154 4.88779 12.0875 5.02997 12.22L10.383 17.573L18.876 4.834C18.9863 4.66852 19.1579 4.55364 19.3529 4.51463C19.5479 4.47562 19.7505 4.51568 19.916 4.626Z"
      fill="#CD1818"
    />
  </svg>
);
