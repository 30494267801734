import ply4 from "../img/ply4.JPG";
import plyT2 from "../img/plyT.JPG";

//----------------Testing yellow ish------------------------------------------------
import movelikeahuman1 from "../img/movelikeahuman1.JPG";
import movelikeahuman2 from "../img/movelikeahuman2.JPG";
import movelikeahuman3 from "../img/movelikeahuman3.JPG";
import movelikeahuman4 from "../img/movelikeahuman4.jpg";
import movelikeahuman5 from "../img/movelikeahuman5.jpg";
import movelikeahuman6 from "../img/movelikeahuman6.JPG";
import movelikeahuman7 from "../img/movelikeahuman7.JPG";
import movelikeahuman8 from "../img/movelikeahuman8.JPG";
import movelikeahuman9 from "../img/movelikeahuman9.JPG";
import movelikeahuman10 from "../img/movelikeahuman10.JPG";
import movelikeahuman11 from "../img/movelikeahuman11.JPG";
import movelikeahuman12 from "../img/movelikeahuman12.JPG";

export const galleryPhoto1 = [
  movelikeahuman5,
  movelikeahuman12,
  movelikeahuman3,
  movelikeahuman2,
  movelikeahuman10,
  ply4,
];
export const galleryPhoto2 = [
  movelikeahuman1,
  movelikeahuman9,
  movelikeahuman7,
  movelikeahuman11,
  plyT2,
  movelikeahuman8,
];

// export { gym1, gym2, gym3, gym4, gym4, gym5, gym6 };
